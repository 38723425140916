@import 'base/variables';

body,
html {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $neutral-darker;
  background-color: $neutral-white;
  font-family: 'opensans-bold', sans-serif !important;
  font-display: fallback;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

body {
  background: $neutral-white;
}

html {
  font-size: 100%;
}

div {
  font-family: 'opensans-regular', sans-serif !important;
  font-display: fallback;
}

img {
  max-width: 100%;
}

textarea {
  resize: none;
}

strong {
  color: $neutral-black;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $neutral-black-transparent-5;
  z-index: 999;
}

.error {
  color: $secondary-negative-tint30;
}

.word-break {
  word-break: break-word;
}

.mat-menu-panel-shadow {
  box-shadow: 0 2px 4px -1px rgba(var(--neutral-black-rgb), 0.2),
    0 4px 5px rgba(var(--neutral-black-rgb), 0.1), 0 1px 10px rgba(var(--neutral-black-rgb), 0.1) !important;
  background-color: $neutral-background;
}

.mat-menu-panel-shadow-dark {
  box-shadow: 0 2px 4px -1px rgba(var(--neutral-black-rgb), 0.2),
    0 4px 5px rgba(var(--neutral-black-rgb), 0.1), 0 1px 10px rgba(var(--neutral-black-rgb), 0.1) !important;
  background-color: $neutral-black;
  button,
  button span {
    color: $neutral-white !important;
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 4px !important;
  background-color: $neutral-white;
  .mat-mdc-option {
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    height: initial !important;
    min-height: initial !important;
  }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel .mat-mdc-option[aria-selected='true'] {
  mat-pseudo-checkbox {
    display: none;
  }
}
.mdc-menu-surface.mat-mdc-autocomplete-panel .mat-mdc-option[aria-disabled='true'] {
  opacity: 1 !important;
  span {
    font-weight: 600;
    color: $primary-base !important;
  }
}

.title,
.header-btn {
  color: $neutral-black;
}
