@import 'variables';
/** Margins **/
.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

spa-ui-header:has(+ spa-ui-tabs) {
  .container {
    padding-bottom: 0px;
  }
}

spa-ui-header + spa-ui-tabs {
  & > .tabs-wrapper {
    background: $neutral-white;
    & > .tabs {
      max-width: 1200px;
      padding: 0 48px;
    }
  }
}
