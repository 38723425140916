@import 'base/variables';

button.spa-button,
.spa-button {
  width: 100%;
  padding: 10px 12px;

  background-color: $primary-base;
  border: 1px solid;
  border-color: $primary-base;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: auto 60%;
  background-position: center center;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: $neutral-white;
  cursor: pointer;

  transition: 200ms all;

  &:hover {
    background-color: $primary-tint30;
    border-color: $primary-tint30;
  }

  &:disabled {
    background-color: $primary-tint50;
    border-color: $primary-tint50;
    cursor: not-allowed;
  }

  &.large {
    padding: 16px 12px;
  }

  &.mobile {
    border-radius: 12px;
  }

  @media screen and (max-width: 1024px) {
    border-radius: 12px;
  }

  &.destructive {
    background-color: $secondary-negative-base;
    border-color: $secondary-negative-base;

    &:hover {
      background-color: $secondary-negative-tint30;
      border-color: $secondary-negative-tint30;
    }

    &:disabled {
      background-color: $secondary-negative-tint50;
      border-color: $secondary-negative-tint50;
      cursor: not-allowed;
    }
  }

  &.loading {
    background-image: url(./../../images/loader/loader-light.svg);
    color: transparent;
  }

  &.secondary {
    background-color: $neutral-background;
    border: 1px solid;
    border-color: $neutral-background;
    color: $neutral-black;

    &:hover {
      background-color: $neutral-lightest;
      border-color: $neutral-lightest;
    }

    &:disabled {
      background-color: $neutral-background;
      border-color: $neutral-lightest;
    }

    &.destructive {
      color: $secondary-negative-base;
      background-color: $neutral-white;
      border-color: $secondary-negative-base;

      &:hover {
        color: $secondary-negative-tint30;
        background-color: $neutral-white;
        border-color: $secondary-negative-tint30;
      }

      &:disabled {
        color: $secondary-negative-tint50;
        background-color: $neutral-white;
        border-color: $secondary-negative-tint50;
        cursor: not-allowed;
      }
    }

    &.loading {
      background-image: url(./../../images/loader/loader-dark.svg);
      color: transparent !important;
    }
  }

  &.transparent-button {
    border: none;
    background-color: transparent;
    color: $primary-base;

    &:hover {
      background-color: $neutral-lightest;
    }
  }

  &.more {
    height: 37px;
    width: 37px;
    border-color: transparent;
    background-color: transparent;
    background-image: url(../../images/icons/20/More.svg);
    background-position: center center;
    background-size: 20px 20px;

    &:hover {
      background-color: $neutral-lightest;
      border-color: $neutral-lightest;
    }

    &:disabled {
      display: none;
    }
  }

  &.basic {
    border: none;
    background-color: transparent;
    color: $primary-base;

    &:hover {
      background-color: $neutral-background;
    }
  }
}
