/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: local('Open Sans Extra Bold'), local('OpenSans-ExtraBold');
  src: url('../../assets/fonts/opensans-extrabold/OpenSans-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/opensans-extrabold/OpenSans-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ /* Legacy iOS */
      url('../../assets/fonts/opensans-extrabold/OpenSans-ExtraBold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/opensans-extrabold/OpenSans-ExtraBold.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/opensans-extrabold/OpenSans-ExtraBold.woff2')
      format('woff2'); /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
}
