.s-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;

  &.white {
    background: $neutral-white;
  }

  &.dark {
    background: $neutral-black;
  }

  &.light {
    background: $neutral-light;
  }

  &.black {
    background: $neutral-black;
  }

  &.red {
    background: $secondary-negative-tint30;
  }

  .button & {
    width: 15px;
    height: 15px;
    margin: 0px 5px 0px -3px;
  }

  &.burger {
    @include iconUri('/assets/icons/burger.svg');
  }

  &.excel {
    @include iconUri('/assets/icons/excel.svg');
  }

  &.image {
    @include iconUri('/assets/icons/image.svg');
  }

  &.additional-dots {
    @include iconUri('/assets/icons/additional-dots.svg');
  }
  &.attach {
    @include iconUri('/assets/icons/attach.svg');
  }
  &.attention {
    @include iconUri('/assets/icons/attention.svg');
  }
  &.attention-full {
    @include iconUri('/assets/icons/attention-full.svg');
  }
  &.calendar {
    @include iconUri('/assets/icons/calendar.svg');
  }
  &.copy {
    @include iconUri('/assets/icons/copy.svg');
  }
  &.cross-circle {
    @include iconUri('/assets/icons/cross-circle.svg');
  }
  &.cross {
    @include iconUri('/assets/icons/cross.svg');
  }
  &.delete {
    @include iconUri('/assets/icons/delete.svg');
  }
  &.document {
    @include iconUri('/assets/icons/document.svg');
  }
  &.download {
    @include iconUri('/assets/icons/download.svg');
  }
  &.error {
    @include iconUri('/assets/icons/error.svg');
  }
  &.expand {
    @include iconUri('/assets/icons/expand.svg');
  }
  &.eye-hide {
    @include iconUri('/assets/icons/eye-hide.svg');
  }
  &.eye-open {
    @include iconUri('/assets/icons/eye-open.svg');
  }
  &.fill-167 {
    @include iconUri('/assets/icons/fill-167.svg');
  }
  &.filter {
    @include iconUri('/assets/icons/filter.svg');
  }
  &.floors {
    @include iconUri('/assets/icons/floors.svg');
  }
  &.heart {
    @include iconUri('/assets/icons/heart.svg');
  }
  &.check-circle {
    @include iconUri('/assets/icons/check-circle.svg');
  }
  &.check-scircle {
    @include iconUri('/assets/icons/check-scircle.svg');
  }
  &.check {
    @include iconUri('/assets/icons/check.svg');
  }
  &.info {
    @include iconUri('/assets/icons/info.svg');
  }
  &.info-full {
    @include iconUri('/assets/icons/info-full.svg');
  }
  &.integrations {
    @include iconUri('/assets/icons/integrations.svg');
  }
  &.like {
    @include iconUri('/assets/icons/like.svg');
  }
  &.like-filled {
    @include iconUri('/assets/icons/like-filled.svg');
  }
  &.location-pin {
    @include iconUri('/assets/icons/location-pin.svg');
  }
  &.lock {
    @include iconUri('/assets/icons/lock.svg');
  }
  &.long-down {
    @include iconUri('/assets/icons/long-down.svg');
  }
  &.long-left {
    @include iconUri('/assets/icons/long-left.svg');
  }
  &.long-right {
    @include iconUri('/assets/icons/long-right.svg');
  }
  &.long-up {
    @include iconUri('/assets/icons/long-up.svg');
  }
  &.mail {
    @include iconUri('/assets/icons/mail.svg');
  }
  &.message {
    @include iconUri('/assets/icons/message.svg');
  }
  &.mini-down {
    @include iconUri('/assets/icons/mini-down.svg');
  }
  &.mini-left {
    @include iconUri('/assets/icons/mini-left.svg');
  }
  &.mini-right {
    @include iconUri('/assets/icons/mini-right.svg');
  }
  &.mini-up {
    @include iconUri('/assets/icons/mini-up.svg');
  }
  &.minus-circle {
    @include iconUri('/assets/icons/minus-circle.svg');
  }
  &.minus {
    @include iconUri('/assets/icons/minus.svg');
  }
  &.notification-off {
    @include iconUri('/assets/icons/notification-off.svg');
  }
  &.notification {
    @include iconUri('/assets/icons/notification.svg');
  }
  &.pause {
    @include iconUri('/assets/icons/pause.svg');
  }
  &.person {
    @include iconUri('/assets/icons/person.svg');
  }
  &.play {
    @include iconUri('/assets/icons/play.svg');
  }
  &.plus-circle {
    @include iconUri('/assets/icons/plus-circle.svg');
  }
  &.plus {
    @include iconUri('/assets/icons/plus.svg');
  }
  &.question-mark {
    @include iconUri('/assets/icons/question-mark.svg');
  }
  &.question-mark-full {
    @include iconUri('/assets/icons/question-mark-full.svg');
  }
  &.reduce {
    @include iconUri('/assets/icons/reduce.svg');
  }
  &.restart {
    @include iconUri('/assets/icons/restart.svg');
  }
  &.roads {
    @include iconUri('/assets/icons/roads.svg');
  }
  &.search {
    @include iconUri('/assets/icons/search.svg');
  }
  &.settings {
    @include iconUri('/assets/icons/settings.svg');
  }
  &.shape-copy {
    @include iconUri('/assets/icons/shape-copy.svg');
  }
  &.share-2 {
    @include iconUri('/assets/icons/share-2.svg');
  }
  &.short-down {
    @include iconUri('/assets/icons/short-down.svg');
  }
  &.short-left {
    @include iconUri('/assets/icons/short-left.svg');
  }
  &.short-right {
    @include iconUri('/assets/icons/short-right.svg');
  }
  &.short-up {
    @include iconUri('/assets/icons/short-up.svg');
  }
  &.star {
    @include iconUri('/assets/icons/star.svg');
  }
  &.star-full {
    @include iconUri('/assets/icons/star-full.svg');
  }
  &.switch-on-off {
    @include iconUri('/assets/icons/switch-on-off.svg');
  }
  &.ui-05 {
    @include iconUri('/assets/icons/ui-05.svg');
  }
  &.unlock {
    @include iconUri('/assets/icons/unlock.svg');
  }
  &.write-edit {
    @include iconUri('/assets/icons/write-edit.svg');
  }

  &.map-temp {
    @include iconUri('/assets/icons/map-temp.svg');
  }

  &.map-hum {
    @include iconUri('/assets/icons/map-hum.svg');
  }

  &.map-chair {
    @include iconUri('/assets/icons/map-chair.svg');
  }

  &.map-co2 {
    @include iconUri('/assets/icons/map-co2.svg');
  }

  &.map-pir {
    @include iconUri('/assets/icons/map-pir.svg');
  }

  &.map-stone {
    @include iconUri('/assets/icons/map-stone.svg');
  }
  &.map-spaces {
    @include iconUri('/assets/icons/map-spaces.svg');
  }

  &.map-markers {
    @include iconUri('/assets/icons/map-markers.svg');
  }

  &.map-floorplan {
    @include iconUri('/assets/icons/map-floorplan.svg');
  }

  &.arrow-right {
    @include iconUri('/assets/icons/arrow-right.svg');
  }
}
