.table {
  background: transparent;
  margin-top: 1.5rem;
}

.table-overflow {
  overflow-x: auto;
}

.table-width {
  min-width: 564px;
}
