@import 'base/variables';

.dialog {
  h1,
  h3 {
    font-size: 25px;
  }

  h3 {
    padding-bottom: 10px;
  }

  .input {
    border: none;
    border-bottom: 1px solid $neutral-background;
    border-radius: 0;
    box-shadow: none;
  }
}

.k-dialog-wrapper {
  .k-dialog-buttongroup {
    border-color: $neutral-white;
    padding-bottom: 48px;
    background: $neutral-white;
    float: right;

    button,
    button.button {
      min-width: 150px;
      height: 36px;
      border-radius: 4px;
      background: $primary-base;
      border: 2px solid $primary-base;
      color: $neutral-white;
      padding: 0;
      flex: 0;
      margin-left: 15px;
      margin-top: 19px;
      font-size: 14px;
      cursor: pointer;
    }

    .second-button {
      border: 2px solid $primary-base;
      background: $neutral-white;
      color: $primary-base;
    }
  }

  .k-window-content,
  .k-prompt-container {
    padding: 0 16px;
  }

  .k-dialog {
    min-width: 410px;
    max-width: 500px;
    padding-left: 30px;
    padding-right: 30px;
    max-height: 90%;
    overflow: auto;
  }

  .k-dialog .k-i-x {
    color: $neutral-dark;
    font-size: 18px;
  }

  .k-dialog .k-window-titlebar {
    padding-top: 0;
    padding-bottom: 12px;
    font-size: 24px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
  }

  .k-dialog,
  .k-header {
    border-radius: 4px !important;
    background-color: $neutral-white;
    border: none;
    border-style: none;
  }

  .k-window-title {
    padding-top: 30px;
    min-height: 60px;
  }

  .k-window-actions {
    margin-top: 20px;
  }

  p {
    font-size: 16px !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: $neutral-darker;
  }

  .k-input.k-textbox,
  .k-textarea,
  .k-textbox {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 12px;
    color: $neutral-darker;
    border: 1px solid $neutral-lightest;
    resize: none !important;
    height: 40px;
    border-radius: 2px;
  }

  .control-label {
    font-size: 14px !important;
    font-weight: bold !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.29 !important;
    letter-spacing: normal !important;
  }

  .k-input.k-textbox.k-state-invalid:not(.ng-untouched),
  .k-input.k-textbox.ng-invalid:not(.ng-untouched),
  .k-input.k-textbox:invalid:not(.ng-valid):not(.ng-untouched),
  .k-textarea.k-state-invalid:not(.ng-untouched),
  .k-textarea.ng-invalid:not(.ng-untouched),
  .k-textarea:invalid:not(.ng-valid):not(.ng-untouched),
  .k-textbox.k-state-invalid:not(.ng-untouched),
  .k-textbox.ng-invalid:not(.ng-untouched),
  .k-textbox:invalid:not(.ng-valid):not(.ng-untouched) {
    color: $secondary-negative-tint30;
    border-color: $secondary-negative-tint30;
  }
}

.k-dialog-wrapper .k-overlay {
  background-color: $neutral-darker-transparent-7;
}

.k-dialog-wrapper .k-textbox.ng-invalid,
.k-dialog-wrapper .k-textbox:invalid:not(.ng-valid) {
  border-color: $neutral-lightest !important;
}

.field-body {
  .field {
    .control > input {
      background-color: $neutral-white;
      border: 1px solid #dbdbdb;
      border-radius: 4px;
    }
  }
}
