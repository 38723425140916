.tabs {
  ul {
    border-bottom: 1px solid $neutral-background !important;
    margin-left: 25px;

    @media only screen and (max-width: 1024px) {
      margin-left: 10px;
    }
  }

  li {
    margin-top: 0 !important;

    a {
      color: $neutral-dark;
    }
  }

  .is-active {
    border-bottom: 3px solid $primary-base;
    color: $neutral-darker !important;
  }
}

.admin-panel {
  padding-top: 80px;
}
