//@import '~bulma/sass/grid/columns';

@mixin shared($options: ()) {
  $configuration: map-merge(
    (
      spaceti-color: #00A4B3,
      button-color: #f2f2f2,
      grey-text-color: #979ba4,
      destroy-color: #fb6d6d,
      success-color: #00A4B3,
      success-hover-color: #4DBFCA,
      second-color: #f5f5f5,
      brand-color: #3B4764,
      white-background-color: white,
    ),
    $options
  );
  spa-grid-wrapper {
    .k-grid ::ng-deep button {
      background: map-get($configuration, button-color);
      color: map-get($configuration, grey-text-color);
      &:hover,
      &:focus {
        background: map-get($configuration, button-color);
        color: map-get($configuration, grey-text-color);
      }
      &.button-destroy {
        color: map-get($configuration, destroy-color);
      }
      &.button-success {
        background: map-get($configuration, success-color);
        color: map-get($configuration, second-color);
        &:hover,
        &:focus {
          background: map-get($configuration, success-hover-color) !important;
          color: map-get($configuration, second-color) !important;
          border-color: map-get($configuration, success-hover-color);
        }
      }
    }
    .k-grid ::ng-deep button.button-destroy {
      color: map-get($configuration, destroy-color);
      &:hover,
      &:focus {
        color: map-get($configuration, destroy-color) !important;
      }
    }
    .k-grid ::ng-deep .cell-paragraph {
      color: map-get($configuration, brand-color);
    }
    .k-grid ::ng-deep tr.k-alt {
      background-color: map-get(
        $configuration,
        white-background-color
      ) !important;
    }
    .k-grid ::ng-deep td {
      color: map-get($configuration, brand-color);
    }
    .k-grid ::ng-deep .k-pager-numbers .k-link,
    .k-pager-numbers .k-link:link {
      color: map-get($configuration, brand-color);
    }
    .k-grid ::ng-deep .k-state-selected {
      background-color: map-get($configuration, spaceti-color) !important;
      color: map-get($configuration, second-color) !important;
    }
    .k-grid ::ng-deep {
      .k-grid-header .k-i-sort-asc-sm,
      .k-grid-header .k-i-sort-desc-sm,
      .k-grid-header .k-sort-order {
        color: map-get($configuration, grey-text-color);
      }
    }
    .k-grid ::ng-deep .k-grid-header th {
      color: map-get($configuration, grey-text-color);
    }
    .grid-btn {
      .fa {
        cursor: pointer;
      }
    }
  }
}
