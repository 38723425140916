/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'OpenSans-Bold';
  src: local('Open Sans Bold'), local('OpenSans-Bold');
  src: url('../../assets/fonts/opensans-bold/OpenSans-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/opensans-bold/OpenSans-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/opensans-bold/OpenSans-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/opensans-bold/OpenSans-Bold.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/opensans-bold/OpenSans-Bold.woff2') format('woff2'); /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
}
