@import 'base/variables';


.content-box {
  padding: 10px 20px;
  background-color: $neutral-background;
  min-height: 100vh;
}


.content li + li {
  margin-top: 0;
}

.content {
  color: $neutral-dark;
  margin-top: 1.5rem;
}

.bold {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.shadow-bg {
  box-shadow: 0 3px 20px $neutral-black-transparent-1;
}

.pt-none {
  padding-top: 0 !important;
}

.pb-none {
  padding-bottom: 0 !important;
}

.mt-none {
  margin-top: 0 !important;
}

.padding-none {
  padding: 0 !important;
}

.pt-20-pb-15 {
  padding-bottom: 15px !important;
  padding-top: 20px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.border-radius-circle {
  border-radius: 500px;
}

.cursor-pointer {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.top-25 {
  top: 25px;
}

.top-18 {
  top: 18px;
}

.top-4 {
  top: 4px;
}

.left-300 {
  left: 300px;
}

.right-20 {
  right: 20px;
}

.right-200 {
  right: 200px;
}

.right-180 {
  right: 180px;
}

.right-0 {
  right: 0;
}

.bg-inherit {
  background: inherit;
}

.white-bg {
  background-color: $neutral-white !important;
}

/* Helper for customizing kendo dropdown (cannot be used inside component due to view encapsulation) */
.no-dropdown-shadow {
  .k-state-focused {
    box-shadow: none !important;
  }
}

@media (max-width: 1024px) {
  .pt-50-tablet {
    padding-top: 50px;
  }
}

div.mdc-tooltip__surface {
  background-color: $neutral-black-transparent-9;
  color: $neutral-white;
  font-size: 14px;
  line-height: 1.43;
  font-weight: normal;
  padding: 15px;
}
