.k-grid button {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  min-width: 80px;
  min-height: 36px;
  background: $neutral-background;
  color: $neutral-light;

  &:hover,
  &:focus {
    background: $neutral-background;
    color: $neutral-light;
    box-shadow: 0 1px 13px $neutral-black-transparent-1;
  }

  &.button-destroy {
    background: transparent;
    color: $secondary-negative-tint30;
  }

  &.button-success {
    background: $primary-base;
    color: $neutral-background;

    &:hover,
    &:focus {
      background: $primary-tint30 !important;
      color: $neutral-background !important;
      border-color: $primary-tint30;
    }
  }
}

.k-grid button.button-destroy {
  background: transparent;
  color: $secondary-negative-tint30;
  border: none;

  &:hover,
  &:focus {
    background: transparent !important;
    color: $secondary-negative-tint30 !important;
    box-shadow: none;
  }
}

.k-grid .grid-btn {
  text-align: right;
}

.k-grid section img {
  max-width: 45px;
  vertical-align: middle;
}

.k-grid .cell-paragraph {
  color: $neutral-darker;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.k-grid tr.k-alt {
  background-color: $neutral-white !important;
}

.k-grid td {
  border-width: 1px 0 0 0 !important;
  color: $neutral-darker;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.k-grid th {
  border-width: 0 0 0 0;
}

.k-grid .k-pager-wrap {
  background-color: $neutral-white !important;
}

.k-grid .k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: $neutral-darker;
}

.k-grid .k-state-selected {
  background-color: $primary-base !important;
  color: $neutral-background !important;
}

.k-grid {
  .k-grid-header .k-i-sort-asc-sm,
  .k-grid-header .k-i-sort-desc-sm,
  .k-grid-header .k-sort-order {
    color: $neutral-light;
  }
}

.k-grid .k-grid-header a,
.k-grid .k-grid-header th {
  font-weight: 600 !important;
}

.k-grid .k-grid-header th {
  padding-bottom: 11px;
}

.k-grid .k-grid-header a {
  padding-top: 11px;
  outline: none;
}

.k-grid .k-grid-header th {
  background-color: $neutral-white;
  color: $neutral-light;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.k-grid tr {
  background-color: $neutral-white;
}

.grid-btn {
  .s-icon {
    cursor: pointer;
  }
}

.k-grid .k-filtercell .k-filtercell-operator,
.k-grid .k-filtercell > span .k-button,
.k-grid .k-filtercell > span .k-dropdown-operator {
  display: none;
}

.k-grid.k-widget {
  border-color: $neutral-lightest;
}

.k-grid {
  display: flex !important;
}
