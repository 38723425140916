:root {
  --neutral-white: #ffffff;
  --neutral-background: #f2f3f7;
  --neutral-lightest: #e5e8f2;
  --neutral-lighter: #c1c6d9;
  --neutral-light: #8891ab;
  --neutral-dark: #6a7591;
  --neutral-darker: #3b4764;
  --neutral-black: #0b1c37;

  --primary-tint90: #e6f6f7;
  --primary-tint80: #ccedf0;
  --primary-tint50: #80d2d9;
  --primary-tint30: #4dbfca;
  --primary-base: #00a4b3;
  --primary-shade20: #00838f;
  --primary-shade40: #00626b;

  --secondary-positive-tint90: #e6f9f0;
  --secondary-positive-tint80: #ccf4e1;
  --secondary-positive-tint50: #80e3b4;
  --secondary-positive-tint30: #4dd796;
  --secondary-positive-base: #00c669;
  --secondary-positive-shade20: #009e54;
  --secondary-positive-shade40: #00773f;

  --secondary-warning-tint90: #fff8e6;
  --secondary-warning-tint80: #fef1cc;
  --secondary-warning-tint50: #fddd80;
  --secondary-warning-tint30: #fccf4d;
  --secondary-warning-base: #fabb00;
  --secondary-warning-shade20: #c89600;
  --secondary-warning-shade40: #967000;

  --secondary-negative-tint90: #fcebe7;
  --secondary-negative-tint80: #f8d6d0;
  --secondary-negative-tint50: #ee9a89;
  --secondary-negative-tint30: #e77159;
  --secondary-negative-base: #dc3412;
  --secondary-negative-shade20: #b02a0e;
  --secondary-negative-shade40: #841f0b;

  --secondary-informational-tint90: #eaf4ff;
  --secondary-informational-tint80: #d4eaff;
  --secondary-informational-tint50: #94caff;
  --secondary-informational-tint30: #69b5ff;
  --secondary-informational-base: #2895ff;
  --secondary-informational-shade20: #2077cc;
  --secondary-informational-shade40: #185999;

  --specific-workstation-tint90: #e6faf6;
  --specific-workstation-tint80: #ccf4ed;
  --specific-workstation-tint50: #80e4d3;
  --specific-workstation-tint30: #4dd9c1;
  --specific-workstation-base: #00c9a6;
  --specific-workstation-shade20: #00a185;
  --specific-workstation-shade40: #007964;

  --specific-meeting-room-tint90: #eef3ff;
  --specific-meeting-room-tint80: #dde8ff;
  --specific-meeting-room-tint50: #abc5ff;
  --specific-meeting-room-tint30: #89adff;
  --specific-meeting-room-base: #578aff;
  --specific-meeting-room-shade20: #466ecc;
  --specific-meeting-room-shade40: #345399;

  --specific-common-space-tint90: #fff6eb;
  --specific-common-space-tint80: #ffecd7;
  --specific-common-space-tint50: #ffd09c;
  --specific-common-space-tint30: #ffbd74;
  --specific-common-space-base: #ffa139;
  --specific-common-space-shade20: #cc812e;
  --specific-common-space-shade40: #996122;

  --specific-parking-tint90: #ecfafd;
  --specific-parking-tint80: #d9f4fa;
  --specific-parking-tint50: #a1e5f3;
  --specific-parking-tint30: #7bdaee;
  --specific-parking-base: #42cae7;
  --specific-parking-shade20: #35a2b9;
  --specific-parking-shade40: #28798b;

  --neutral-white-rgb: 255, 255, 255;
  --neutral-background-rgb: 242, 243, 247;
  --neutral-light-rgb: 136, 145, 171;
  --neutral-darker-rgb: 59, 71, 100;
  --neutral-black-rgb: 11, 28, 55;
  --primary-base-rgb: 0, 164, 179;
  --secondary-positive-base-rgb: 0, 198, 105;
  --secondary-warning-base-rgb: 250, 187, 0;
  --secondary-negative-base-rgb: 220, 52, 18;
  --specific-meeting-room-base-rgb: 87, 138, 255;

  [data-theme='galp-orange'] {
    --neutral-white: #ffffff;
    --neutral-background: #f5f5f5;
    --neutral-lightest: #e0e0e0;
    --neutral-lighter: #bdbdbd;
    --neutral-light: #808080;
    --neutral-dark: #4d4d4d;
    --neutral-darker: #262626;
    --neutral-black: #000000;

    --primary-tint90: #feeee8;
    --primary-tint80: #feddd2;
    --primary-tint50: #fcaa8e;
    --primary-tint30: #fc9978;
    --primary-base: #fa551e;
    --primary-shade20: #c84418;
    --primary-shade40: #963312;

    --secondary-positive-tint90: #e6f9f0;
    --secondary-positive-tint80: #ccf4e1;
    --secondary-positive-tint50: #80e3b4;
    --secondary-positive-tint30: #4dd796;
    --secondary-positive-base: #00c669;
    --secondary-positive-shade20: #009e54;
    --secondary-positive-shade40: #00773f;

    --secondary-warning-tint90: #fff8e6;
    --secondary-warning-tint80: #fef1cc;
    --secondary-warning-tint50: #fddd80;
    --secondary-warning-tint30: #fccf4d;
    --secondary-warning-base: #fabb00;
    --secondary-warning-shade20: #c89600;
    --secondary-warning-shade40: #967000;

    --secondary-negative-tint90: #fcebe7;
    --secondary-negative-tint80: #f8d6d0;
    --secondary-negative-tint50: #ee9a89;
    --secondary-negative-tint30: #e77159;
    --secondary-negative-base: #dc3412;
    --secondary-negative-shade20: #b02a0e;
    --secondary-negative-shade40: #841f0b;

    --secondary-informational-tint90: #eaf4ff;
    --secondary-informational-tint80: #d4eaff;
    --secondary-informational-tint50: #94caff;
    --secondary-informational-tint30: #69b5ff;
    --secondary-informational-base: #2895ff;
    --secondary-informational-shade20: #2077cc;
    --secondary-informational-shade40: #185999;

    --specific-workstation-tint90: #e6faf6;
    --specific-workstation-tint80: #ccf4ed;
    --specific-workstation-tint50: #80e4d3;
    --specific-workstation-tint30: #4dd9c1;
    --specific-workstation-base: #00c9a6;
    --specific-workstation-shade20: #00a185;
    --specific-workstation-shade40: #007964;

    --specific-meeting-room-tint90: #eef3ff;
    --specific-meeting-room-tint80: #dde8ff;
    --specific-meeting-room-tint50: #abc5ff;
    --specific-meeting-room-tint30: #89adff;
    --specific-meeting-room-base: #578aff;
    --specific-meeting-room-shade20: #466ecc;
    --specific-meeting-room-shade40: #345399;

    --specific-common-space-tint90: #fff6eb;
    --specific-common-space-tint80: #ffecd7;
    --specific-common-space-tint50: #ffd09c;
    --specific-common-space-tint30: #ffbd74;
    --specific-common-space-base: #ffa139;
    --specific-common-space-shade20: #cc812e;
    --specific-common-space-shade40: #996122;

    --specific-parking-tint90: #ecfafd;
    --specific-parking-tint80: #d9f4fa;
    --specific-parking-tint50: #a1e5f3;
    --specific-parking-tint30: #7bdaee;
    --specific-parking-base: #42cae7;
    --specific-parking-shade20: #35a2b9;
    --specific-parking-shade40: #28798b;

    --neutral-white-rgb: 255, 255, 255;
    --neutral-background-rgb: 245, 245, 245;
    --neutral-light-rgb: 128, 128, 128;
    --neutral-darker-rgb: 38, 38, 38;
    --neutral-black-rgb: 0, 0, 0;
    --primary-base-rgb: 250, 85, 30;
    --secondary-positive-base-rgb: 0, 198, 105;
    --secondary-warning-base-rgb: 250, 187, 0;
    --secondary-negative-base-rgb: 220, 52, 18;
    --specific-meeting-room-base-rgb: 87, 138, 255;
  }

  [data-theme='green'] {
    --neutral-white: #ffffff;
    --neutral-background: #e6f9f0;
    --neutral-lightest: #ccf4e1;
    --neutral-lighter: #80e3b4;
    --neutral-light: #4dd796;
    --neutral-dark: #00c669;
    --neutral-darker: #009e54;
    --neutral-black: #00773f;

    --primary-tint90: #fff8e6;
    --primary-tint80: #fef1cc;
    --primary-tint50: #fddd80;
    --primary-tint30: #fccf4d;
    --primary-base: #fabb00;
    --primary-shade20: #c89600;
    --primary-shade40: #967000;

    --secondary-positive-tint90: #e6f9f0;
    --secondary-positive-tint80: #ccf4e1;
    --secondary-positive-tint50: #80e3b4;
    --secondary-positive-tint30: #4dd796;
    --secondary-positive-base: #00c669;
    --secondary-positive-shade20: #009e54;
    --secondary-positive-shade40: #00773f;

    --secondary-warning-tint90: #fff8e6;
    --secondary-warning-tint80: #fef1cc;
    --secondary-warning-tint50: #fddd80;
    --secondary-warning-tint30: #fccf4d;
    --secondary-warning-base: #fabb00;
    --secondary-warning-shade20: #c89600;
    --secondary-warning-shade40: #967000;

    --secondary-negative-tint90: #fcebe7;
    --secondary-negative-tint80: #f8d6d0;
    --secondary-negative-tint50: #ee9a89;
    --secondary-negative-tint30: #e77159;
    --secondary-negative-base: #dc3412;
    --secondary-negative-shade20: #b02a0e;
    --secondary-negative-shade40: #841f0b;

    --secondary-informational-tint90: #eaf4ff;
    --secondary-informational-tint80: #d4eaff;
    --secondary-informational-tint50: #94caff;
    --secondary-informational-tint30: #69b5ff;
    --secondary-informational-base: #2895ff;
    --secondary-informational-shade20: #2077cc;
    --secondary-informational-shade40: #185999;

    --specific-workstation-tint90: #e6faf6;
    --specific-workstation-tint80: #ccf4ed;
    --specific-workstation-tint50: #80e4d3;
    --specific-workstation-tint30: #4dd9c1;
    --specific-workstation-base: #00c9a6;
    --specific-workstation-shade20: #00a185;
    --specific-workstation-shade40: #007964;

    --specific-meeting-room-tint90: #eef3ff;
    --specific-meeting-room-tint80: #dde8ff;
    --specific-meeting-room-tint50: #abc5ff;
    --specific-meeting-room-tint30: #89adff;
    --specific-meeting-room-base: #578aff;
    --specific-meeting-room-shade20: #466ecc;
    --specific-meeting-room-shade40: #345399;

    --specific-common-space-tint90: #fff6eb;
    --specific-common-space-tint80: #ffecd7;
    --specific-common-space-tint50: #ffd09c;
    --specific-common-space-tint30: #ffbd74;
    --specific-common-space-base: #ffa139;
    --specific-common-space-shade20: #cc812e;
    --specific-common-space-shade40: #996122;

    --specific-parking-tint90: #ecfafd;
    --specific-parking-tint80: #d9f4fa;
    --specific-parking-tint50: #a1e5f3;
    --specific-parking-tint30: #7bdaee;
    --specific-parking-base: #42cae7;
    --specific-parking-shade20: #35a2b9;
    --specific-parking-shade40: #28798b;

    --neutral-white-rgb: 255, 255, 255;
    --neutral-background-rgb: 230, 249, 240;
    --neutral-light-rgb: 77, 215, 150;
    --neutral-darker-rgb: 0, 158, 84;
    --neutral-black-rgb: 0, 119, 63;
    --primary-base-rgb: 250, 187, 0;
    --secondary-positive-base-rgb: 0, 198, 105;
    --secondary-warning-base-rgb: 250, 187, 0;
    --secondary-negative-base-rgb: 220, 52, 18;
    --specific-meeting-room-base-rgb: 87, 138, 255;
  }

  [data-theme='dark'] {
    --neutral-white: #e0e0e0;
    --neutral-background: #121212;
    --neutral-lightest: #1e1e1e;
    --neutral-lighter: #2c2c2c;
    --neutral-light: #3a3a3a;
    --neutral-dark: #484848;
    --neutral-darker: #565656;
    --neutral-black: #000000;

    --primary-tint90: #00363a;
    --primary-tint80: #004f54;
    --primary-tint50: #007f85;
    --primary-tint30: #009ea6;
    --primary-base: #00bcd4;
    --primary-shade20: #0097a7;
    --primary-shade40: #006978;

    --secondary-positive-tint90: #003a2e;
    --secondary-positive-tint80: #005a47;
    --secondary-positive-tint50: #008a6a;
    --secondary-positive-tint30: #00a97f;
    --secondary-positive-base: #00c853;
    --secondary-positive-shade20: #009624;
    --secondary-positive-shade40: #006400;

    --secondary-warning-tint90: #3a2e00;
    --secondary-warning-tint80: #5a4700;
    --secondary-warning-tint50: #8a6a00;
    --secondary-warning-tint30: #a97f00;
    --secondary-warning-base: #ffc107;
    --secondary-warning-shade20: #ffab00;
    --secondary-warning-shade40: #ff6f00;

    --secondary-negative-tint90: #3a0000;
    --secondary-negative-tint80: #5a0000;
    --secondary-negative-tint50: #8a0000;
    --secondary-negative-tint30: #a70000;
    --secondary-negative-base: #d50000;
    --secondary-negative-shade20: #b71c1c;
    --secondary-negative-shade40: #7f0000;

    --secondary-informational-tint90: #002e3a;
    --secondary-informational-tint80: #00475a;
    --secondary-informational-tint50: #00708a;
    --secondary-informational-tint30: #008fa7;
    --secondary-informational-base: #00b0ff;
    --secondary-informational-shade20: #0091ea;
    --secondary-informational-shade40: #0064b7;

    --specific-workstation-tint90: #003a2e;
    --specific-workstation-tint80: #005a47;
    --specific-workstation-tint50: #008a6a;
    --specific-workstation-tint30: #00a97f;
    --specific-workstation-base: #00c853;
    --specific-workstation-shade20: #009624;
    --specific-workstation-shade40: #006400;

    --specific-meeting-room-tint90: #1a237e;
    --specific-meeting-room-tint80: #283593;
    --specific-meeting-room-tint50: #3f51b5;
    --specific-meeting-room-tint30: #5c6bc0;
    --specific-meeting-room-base: #7986cb;
    --specific-meeting-room-shade20: #5c6bc0;
    --specific-meeting-room-shade40: #3f51b5;

    --specific-common-space-tint90: #3a2e00;
    --specific-common-space-tint80: #5a4700;
    --specific-common-space-tint50: #8a6a00;
    --specific-common-space-tint30: #a97f00;
    --specific-common-space-base: #ffc107;
    --specific-common-space-shade20: #ffab00;
    --specific-common-space-shade40: #ff6f00;

    --specific-parking-tint90: #003a3a;
    --specific-parking-tint80: #005a5a;
    --specific-parking-tint50: #008a8a;
    --specific-parking-tint30: #00a9a9;
    --specific-parking-base: #00e5ff;
    --specific-parking-shade20: #00b8d4;
    --specific-parking-shade40: #00838f;

    --neutral-white-rgb: 224, 224, 224;
    --neutral-background-rgb: 18, 18, 18;
    --neutral-light-rgb: 58, 58, 58;
    --neutral-darker-rgb: 86, 86, 86;
    --neutral-black-rgb: 0, 0, 0;
    --primary-base-rgb: 0, 188, 212;
    --secondary-positive-base-rgb: 0, 200, 83;
    --secondary-warning-base-rgb: 255, 193, 7;
    --secondary-negative-base-rgb: 213, 0, 0;
    --specific-meeting-room-base-rgb: 121, 134, 203;
  }
}
