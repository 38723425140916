$primary: #00A4B3;



/* COLOR PLATES */
$neutral-white: var(--neutral-white);
$neutral-background: var(--neutral-background);
$neutral-lightest: var(--neutral-lightest);
$neutral-lighter: var(--neutral-lighter);
$neutral-light: var(--neutral-light);
$neutral-dark: var(--neutral-dark);
$neutral-darker: var(--neutral-darker);
$neutral-black: var(--neutral-black);

$primary-tint90: var(--primary-tint90);
$primary-tint80: var(--primary-tint80);
$primary-tint50: var(--primary-tint50);
$primary-tint30: var(--primary-tint30);
$primary-base: var(--primary-base);
$primary-shade20: var(--primary-shade20);
$primary-shade40: var(--primary-shade40);

$secondary-positive-tint90: var(--secondary-positive-tint90);
$secondary-positive-tint80: var(--secondary-positive-tint80);
$secondary-positive-tint50: var(--secondary-positive-tint50);
$secondary-positive-tint30: var(--secondary-positive-tint30);
$secondary-positive-base: var(--secondary-positive-base);
$secondary-positive-shade20: var(--secondary-positive-shade20);
$secondary-positive-shade40: var(--secondary-positive-shade40);

$secondary-warning-tint90: var(--secondary-warning-tint90);
$secondary-warning-tint80: var(--secondary-warning-tint80);
$secondary-warning-tint50: var(--secondary-warning-tint50);
$secondary-warning-tint30: var(--secondary-warning-tint30);
$secondary-warning-base: var(--secondary-warning-base);
$secondary-warning-shade20: var(--secondary-warning-shade20);
$secondary-warning-shade40: var(--secondary-warning-shade40);

$secondary-negative-tint90: var(--secondary-negative-tint90);
$secondary-negative-tint80: var(--secondary-negative-tint80);
$secondary-negative-tint50: var(--secondary-negative-tint50);
$secondary-negative-tint30: var(--secondary-negative-tint30);
$secondary-negative-base: var(--secondary-negative-base);
$secondary-negative-shade20: var(--secondary-negative-shade20);
$secondary-negative-shade40: var(--secondary-negative-shade40);

$secondary-informational-tint90: var(--secondary-informational-tint90);
$secondary-informational-tint80: var(--secondary-informational-tint80);
$secondary-informational-tint50: var(--secondary-informational-tint50);
$secondary-informational-tint30: var(--secondary-informational-tint30);
$secondary-informational-base: var(--secondary-informational-base);
$secondary-informational-shade20: var(--secondary-informational-shade20);
$secondary-informational-shade40: var(--secondary-informational-shade40);

$specific-workstation-tint90: var(--specific-workstation-tint90);
$specific-workstation-tint80: var(--specific-workstation-tint80);
$specific-workstation-tint50: var(--specific-workstation-tint50);
$specific-workstation-tint30: var(--specific-workstation-tint30);
$specific-workstation-base: var(--specific-workstation-base);
$specific-workstation-shade20: var(--specific-workstation-shade20);
$specific-workstation-shade40: var(--specific-workstation-shade40);

$specific-meeting-room-tint90: var(--specific-meeting-room-tint90);
$specific-meeting-room-tint80: var(--specific-meeting-room-tint80);
$specific-meeting-room-tint50: var(--specific-meeting-room-tint50);
$specific-meeting-room-tint30: var(--specific-meeting-room-tint30);
$specific-meeting-room-base: var(--specific-meeting-room-base);
$specific-meeting-room-shade20: var(--specific-meeting-room-shade20);
$specific-meeting-room-shade40: var(--specific-meeting-room-shade40);

$specific-common-space-tint90: var(--specific-common-space-tint90);
$specific-common-space-tint80: var(--specific-common-space-tint80);
$specific-common-space-tint50: var(--specific-common-space-tint50);
$specific-common-space-tint30: var(--specific-common-space-tint30);
$specific-common-space-base: var(--specific-common-space-base);
$specific-common-space-shade20: var(--specific-common-space-shade20);
$specific-common-space-shade40: var(--specific-common-space-shade40);

$specific-parking-tint90: var(--specific-parking-tint90);
$specific-parking-tint80: var(--specific-parking-tint80);
$specific-parking-tint50: var(--specific-parking-tint50);
$specific-parking-tint30: var(--specific-parking-tint30);
$specific-parking-base: var(--specific-parking-base);
$specific-parking-shade20: var(--specific-parking-shade20);
$specific-parking-shade40: var(--specific-parking-shade40);

$neutral-black-transparent-1: rgba(var(--neutral-black-rgb), 0.1);
$neutral-black-transparent-2: rgba(var(--neutral-black-rgb), 0.2);
$neutral-black-transparent-5: rgba(var(--neutral-black-rgb), 0.5);
$neutral-black-transparent-7: rgba(var(--neutral-black-rgb), 0.7);
$neutral-black-transparent-9: rgba(var(--neutral-black-rgb), 0.9);

$neutral-light-transparent-4: rgba(var(--neutral-light-rgb), 0.4);
$neutral-light-transparent-7: rgba(var(--neutral-light-rgb), 0.7);

$neutral-white-transparent-3: rgba(var(--neutral-white-rgb), 0.3);
$neutral-white-transparent-5: rgba(var(--neutral-white-rgb), 0.5);
$neutral-white-transparent-9: rgba(var(--neutral-white-rgb), 0.9);

$neutral-darker-transparent-2: rgba(var(--neutral-darker-rgb), 0.2);
$neutral-darker-transparent-7: rgba(var(--neutral-darker-rgb), 0.7);

$primary-transparent-1: rgba(var(--primary-base-rgb), 0.1);
$primary-transparent-3: rgba(var(--primary-base-rgb), 0.3);

$secondary-positive-base-transparent-1: rgba(var(--secondary-positive-base-rgb), 0.1);
$secondary-positive-base-transparent-5: rgba(var(--secondary-positive-base-rgb), 0.5);

$secondary-negative-base-transparent-1: rgba(var(--secondary-negative-base-rgb), 0.1);
$secondary-negative-base-transparent-2: rgba(var(--secondary-negative-base-rgb), 0.2);

$neutral-background-transparent-5: rgba(var(--neutral-background-rgb), 0.5);

$secondary-warning-base-transparent-1: rgba(var(--secondary-warning-base-rgb), 0.1);

$specific-meeting-room-base-transparent-1: rgba(var(--specific-meeting-room-base-rgb), 0.1);



$parking-image: '/assets/images/left-menu/parking.svg';
$common-image: '/assets/images/left-menu/common-space.svg';
$meeting-image: '/assets/images/left-menu/meeting-room.svg';
$workspace-image: '/assets/images/left-menu/workspace.svg';
$other-image: '/assets/images/general/dots.svg';
$space-groups-image: '/assets/images/general/space-groups.svg';

$stone-bg: '/assets/images/insights/stone-bg.svg';
$wellbeing-image: '/assets/images/insights/wellbeing.svg';
$temperature-image: '/assets/images/insights/temperature.svg';
$peoplecount-image: '/assets/images/insights/peoplecount.svg';
$brightness-image: '/assets/images/insights/brightness.svg';
$humidity-image: '/assets/images/insights/humidity.svg';
$co2-image: '/assets/images/insights/cloud-co-2.svg';
$energy-image: '/assets/images/insights/energy.svg';
$sound-image: '/assets/images/insights/sound.svg';
