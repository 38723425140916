@import 'variables';
/* Setup */
html {
  /* Change default typefaces here */
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
}
/* Copy & Lists */
p, span {
  line-height: 16px;
  //margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}
ul,
ol {
  //margin-top: 1.5rem;
  //margin-bottom: 1.5rem;
}
ul li,
ol li {
  line-height: 1.5rem;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
blockquote {
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  /* Change heading typefaces here */
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  //margin-top: 1.5rem;
  margin-bottom: 0;
  line-height: 1.5rem;
  color: $neutral-black;
}
h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
h5 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
/* Tables */
table {
  margin-top: 1.5rem;
  border-spacing: 0px;
  border-collapse: collapse;
}
table td,
table th {
  padding: 0;
  line-height: 33px;
}
