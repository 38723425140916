* {
  outline: none;

  &:active,
  :focus {
    outline: none !important;
  }
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

a,
img {
  border: 0;
}

::-moz-selection {
  background-color: $primary-base;
  color: $neutral-background;
}

::selection {
  background-color: $primary-base;
  color: $neutral-background;
}
