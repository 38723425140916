//menu max 768

$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$grid__cols: 12;

$map-grid-props: (
  '': 0,
  '-sm': $grid__bp-sm,
  '-md': $grid__bp-md,
  '-lg': $grid__bp-lg,
  '-xl': $grid__bp-xl,
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    .col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-#{$i} {
        flex-basis: (100 / ($grid-cols / $i)) * 1%;
      }
      .col#{$modifier}-offset-#{$i} {
        margin-left: (100 / ($grid-cols / $i)) * 1%;
      }
    }
  }
}

.container {
  display: flex;
  max-width: $grid__bp-xl * 1px;
  width: 100%;
  margin: 0 auto;

  padding: 24px 48px;

  &.fluid {
    margin: 0;
    max-width: 100%;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    &.gap {
      flex-wrap: nowrap;
      gap: 24px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    //width: 100%;
    gap: 24px;
    margin: 0px;
    //padding: 0px;
  }
  .center {
    justify-content: center;
  }
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == '') {
    $modifier: '-xs';
  }
  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'max') {
    .wrap#{$modifier} {
      flex-wrap: wrap !important;
    }
  }
}
