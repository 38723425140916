@import 'base/variables';



.spa-chip {
  display: inline-block;
  padding: 2px 5px;
  background-color: $neutral-lightest;
  border-radius: 10px;

  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $neutral-black;

  &.blue {
    background-color: $secondary-informational-tint90;
    color: $secondary-informational-shade20;
  }

  &.green {
    background-color: $secondary-positive-tint90;
    color: $secondary-positive-shade20;
  }

  &.yellow {
    background-color: $secondary-warning-base-transparent-1;
    color: $secondary-warning-shade20;
  }

   &.red {
    background-color: $secondary-negative-base-transparent-1;
    color: $secondary-negative-shade20;
   }
}
