/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: 'OpenSans-Regular';
  src: local('Open Sans Regular'), local('OpenSans-Regular');
  src: url('../../assets/fonts/opensans-regular/OpenSans-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/opensans-regular/OpenSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/opensans-regular/OpenSans-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/opensans-regular/OpenSans-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/opensans-regular/OpenSans-Regular.woff2')
      format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
